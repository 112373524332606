import nav from './components/nav';
import scrollAnimation from './components/scrollAnimation';
import progressbar from './components/progressbar';
import modal from './components/modal';
import slider from './components/slider';
import backToTop from './components/back-to-top'

import '../styles/index.scss';

// Nav
nav();

document.addEventListener('DOMContentLoaded', () => {
    // Animate blocks on scroll
    scrollAnimation();

    // Animate progress circles on scroll
    progressbar();

    // Modal
    modal();

    // Slider
    slider();

    // Back to top button
    backToTop();
});
