const backToTop = () => {
    let visibility = false;
    const btn = document.querySelector('.js-back-to-top');

    const checkVisibility = () => {
        const {scrollY, innerHeight} = window;

        if (scrollY > innerHeight) {
            if (!visibility) {
                btn.classList.add('is-visible');
                visibility = true;
            }
        } else {
            if (visibility) {
                btn.classList.remove('is-visible');
                visibility = false;
            }
        }
    }

    checkVisibility();

    window.addEventListener('scroll', checkVisibility);
}

export default backToTop;
