import Swiper from 'swiper';

const slider = () => {
    // eslint-disable-next-line
    const swiper = new Swiper('.js-swiper-container', {
        direction: 'horizontal',
        spaceBetween: 30,
        grabCursor: true,
        watchSlidesVisibility: true,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
            600: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
            }
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },
    });

    // eslint-disable-next-line
    const swiperRs = new Swiper('.js-swiper-container-rs', {
        direction: 'horizontal',
        spaceBetween: 30,
        grabCursor: true,
        watchSlidesVisibility: true,
        breakpoints: {
            1024: {
                slidesPerView: 4,
            },
            600: {
                slidesPerView: 3,
            },
            320: {
                slidesPerView: 2,
            }
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },
    });
}

export default slider;
