// eslint-disable-next-line
import MicroModal from 'micromodal';

const modal = () => {
    MicroModal.init({
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
    });
}

export default modal;