const progressbar = () => {
    const animate = (el, action) => {
        const percent = el.getAttribute('data-percentage');
        const circle = el.querySelector('circle');
        const circleComplete = el.querySelector('circle.complete');
        const radius = circle.getAttribute('r');
        const circumference = 2 * Math.PI * radius;
        const strokeDashOffset = circumference - ((percent * circumference) / 100);
        action === 'fill' ?
            circleComplete.style.strokeDashoffset = strokeDashOffset.toString() :
            circleComplete.removeAttribute('style');
    }

    document.addEventListener('aos:in', ({detail}) => {
        if (detail.classList.contains('js-radial-progress-wrap')) {
            const child = detail.querySelector('.js-radial-progress');
            animate(child, 'fill');
        }
    });

    document.addEventListener('aos:out', ({detail}) => {
        if (detail.classList.contains('js-radial-progress-wrap')) {
            const child = detail.querySelector('.js-radial-progress');
            setTimeout(function(){
                animate(child);
            }, 500);
        }
    });
}

export default progressbar;
